export const LS_TOKEN = 'hotels';
export const APP_API_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_WS_URL = process.env.REACT_APP_WS_BASE_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const USE_LOCAL_STORAGE = process.env.REACT_APP_USE_LOCAL_STORAGE === 'false' ? false : true;

export const DADATA_TOKEN = 'eb82fbe97327290c0cce5e280b59a6314dec4fc5';

export const DRAWER_WIDTH = 256;

export const POST_LOGIN = '/auth/token/';
export const POST_REFRESH = '/auth/token/refresh/';

export const POST_ACTIVATION = '/auth/users/activation/';
export const POST_RESET_PASSWORD_CONFIRM = '/auth/users/reset_password_confirm/';

export const GET_USERS_ME = '/auth/users/me/';
export const PATCH_USERS_ME = '/auth/users/me/';
export const POST_SET_PASSWORD = '/dashboard/users/set_password/';

export const GET_STAYS = '/dashboard/stays/';
export const GET_STAY = '/dashboard/stays/:id/';
export const POST_STAY_SET_ROOM = '/dashboard/stays/:id/set_room/';

export const GET_SERVICE_ORDERS_STATUSES_LIST = '/service_orders/statuses/';
export const GET_LANGUAGES_LIST = '/config/langs/';

export const GET_SERVICE_ORDERS_REPORT = '/dashboard/service_orders/report/';
export const GET_SERVICE_ORDERS = '/dashboard/service_orders/';
export const GET_SERVICE_ORDERS_STATUSES = '/dashboard/service_orders/status_report/';

export const GET_ORDER = '/dashboard/service_orders/:id/';
export const GET_ORDER_TAKE = '/dashboard/service_orders/:id/take_order/';
export const GET_ORDER_CANCEL = '/dashboard/service_orders/:id/cancel_order/';
export const GET_ORDER_COMPLETE = '/dashboard/service_orders/:id/complete_order/';
export const GET_ORDER_HISTORY = '/dashboard/service_orders/:id/history/';

export const POST_TRANSLATE_MESSAGE = '/messages/:id/translate/';

export const GET_HOTEL = '/dashboard/hotels/:id/';
export const PATCH_HOTEL = '/dashboard/hotels/:id/';

export const GET_HOTEL_CURRENCIES = '/dashboard/hotels/currency_list/';
export const GET_HOTEL_FIELD_RESTRICTIONS = '/hotels/field_restrictions/';

export const GET_PHOTOS = '/images/:type/:id/';
export const POST_PHOTOS = '/dashboard/images/upload/';

export const DELETE_PHOTO = '/dashboard/images/:id/';
export const POST_PHOTO_SET_MAIN = '/dashboard/images/:id/set_main/';

export const GET_CATEGORIES = '/categories/';
export const GET_FILLED_CATEGORIES = '/categories/filled/';

export const GET_FACILITIES = '/dashboard/facilities_for_select/';
export const POST_FACILITY_SELECT = '/dashboard/facilities_for_select/:id/select/';
export const POST_FACILITY_UNSELECT = '/dashboard/facilities_for_select/:id/unselect/';

export const GET_SERVICES = '/dashboard/services/';
export const POST_SERVICE = '/dashboard/services/';
export const PUT_SERVICE = '/dashboard/services/:id/';
export const DELETE_SERVICE = '/dashboard/services/:id/';

export const GET_VCATEGORIES = '/dashboard/virtual-categories/';
export const POST_VCATEGORY = '/dashboard/virtual-categories/';
export const PATCH_VCATEGORY = '/dashboard/virtual-categories/:id/';
export const DELETE_VCATEGORY = '/dashboard/virtual-categories/:id/';

export const GET_VCATEGORY_ITEMS = '/dashboard/virtual-categories-items/';
export const POST_VCATEGORY_ITEM = '/dashboard/virtual-categories-items/';
export const PATCH_VCATEGORY_ITEM = '/dashboard/virtual-categories-items/:id/';
export const DELETE_VCATEGORY_ITEM = '/dashboard/virtual-categories-items/:id/';

export const POST_VCATEGORY_ITEMS_SORT = '/dashboard/virtual-categories-items/sort/';

export const GET_CHANNELS_TREE_FOR_HOTELS = '/dashboard/channels/tree_for_hotel/';
export const GET_POSITIONS_TREE_FOR_HOTELS = '/dashboard/positions/tree_for_hotel/';

export const POST_CHANNELS_ADD_CHILD_NODE = '/dashboard/channels/:id/add_child_node/';
export const POST_POSITIONS_ADD_CHILD_NODE = '/dashboard/positions/:id/add_child_node/';

export const POST_CHANNELS_RENAME_NODE = '/dashboard/channels/:id/rename_node/';
export const POST_POSITIONS_RENAME_NODE = '/dashboard/positions/:id/rename_node/';

export const DELETE_CHANNEL = '/dashboard/channels/:id/';
export const DELETE_POSITION = '/dashboard/positions/:id/';

export const GET_CHANNEL_SUBSCRIBED_POSITIONS = '/dashboard/channels/:id/subscribed_positions/';
export const GET_CHANNEL_SUBSCRIBED_USERS = '/dashboard/channels/:id/subscribed_users/';
export const GET_POSITION_CHANNELS = '/dashboard/positions/:id/channels/';

export const GET_ACCOUNTS_STAFF = '/dashboard/accounts/staff/';
export const POST_ACCOUNTS_ADD_STAFF = '/dashboard/accounts/add_staff/';
export const PATCH_ACCOUNTS_EDIT_STAFF = '/dashboard/accounts/:id/';
export const POST_ACCOUNTS_DEACTIVATE_STAFF = '/dashboard/accounts/:id/deactivate/';

export const POST_SUBSCRIBE_TO_CHANNEL = '/dashboard/channels/:id/subscribe/';
export const POST_UNSUBSCRIBE_FROM_CHANNEL = '/dashboard/channels/:id/unsubscribe/';

export const POST_ADD_POSITION_TO_USER = '/dashboard/positions/:id/add_to_user/';
export const POST_REMOVE_POSITION_FROM_USER = '/dashboard/positions/:id/remove_from_user/';

export const GET_BILLING_POINTS = '/dashboard/billing/payment_points/';
export const POST_BILLING_POINT = '/dashboard/billing/payment_points/';

export const GET_BILLING_GATEWAYS = '/dashboard/billing/gateways/';

export const GET_PROMO_LANDINGS = '/dashboard/promo/landings/';
export const POST_PROMO_LANDING = '/dashboard/promo/landings/';
export const PATCH_PROMO_LANDING = '/dashboard/promo/landings/:id/';
export const DELETE_PROMO_LANDING = '/dashboard/promo/landings/:id/';

export const GET_ITEM_TYPES = '/dashboard/services/service_types/';
export const GET_APPROVE_TYPES = '/dashboard/services/approve_types/';
export const GET_BOOKING_TYPES = '/dashboard/services/booking_types/';
export const GET_GEO_LEVELS = '/dashboard/services/geo_levels/';
export const GET_FEEDBACK_VARIANTS = '/dashboard/services/feedback_variants/';

export const GET_MENUS = '/menus/';
export const POST_MENU = '/dashboard/menus/';
export const PATCH_MENU = '/dashboard/menus/:id/';
export const DELETE_MENU = '/dashboard/menus/:id/';

export const GET_MENUS_TYPES = '/dashboard/menus/types/';

export const GET_MENU_ITEMS = '/dashboard/menu_items/';
export const POST_MENU_ITEM = '/dashboard/menu_items/';
export const PATCH_MENU_ITEM = '/dashboard/menu_items/:id/';
export const DELETE_MENU_ITEM = '/dashboard/menu_items/:id/';

export const POST_MENU_ITEMS_SORT = '/dashboard/menu_items/sort/';

export const GET_SAFETY_CATEGORIES = '/content/safety-categories/';
export const GET_SAFETY_ITEMS = '/dashboard/content/safety/';
export const POST_SAFETY_ITEM = '/dashboard/content/safety/';
export const PATCH_SAFETY_ITEM = '/dashboard/content/safety/:id/';
export const DELETE_SAFETY_ITEM = '/dashboard/content/safety/:id/';

export const GET_SIGHTS_CATEGORIES = '/content/sights-categories/';
export const GET_SIGHTS_ITEMS = '/dashboard/content/sights/';
export const POST_SIGHTS_ITEM = '/dashboard/content/sights/';
export const PATCH_SIGHTS_ITEM = '/dashboard/content/sights/:id/';
export const DELETE_SIGHTS_ITEM = '/dashboard/content/sights/:id/';
