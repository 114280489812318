import { AuthProvider } from './auth-context';
import { UserProvider } from './user-context';
import { OrdersProvider } from './orders-context';
import { HelpersProvider } from './helpers-context';

const AppProviders = ({ children }) => {
  return (
    <AuthProvider>
      <UserProvider>
        <HelpersProvider>
          <OrdersProvider>{children}</OrdersProvider>
        </HelpersProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export { AppProviders };
